/**
 *  custom radio
 */
ion-radio::part(container) {
  width: 25px;
  height: 25px;

  border-radius: 100%;
  border: 1px solid var(--app-input-border-color);
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: var(--app-theme-color);
  border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
  width: 5px;
  height: 9px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);

  position: relative;
  top: -1px;
}

/**
 *  custom checkbox
 */
ion-checkbox {
  --size: 25px;
  --checkbox-background-checked: var(--app-theme-color);
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 1px solid var(--app-input-border-color);
}

ion-checkbox.checkbox-checked::part(container) {
  border: 1px solid var(--app-theme-color);
}